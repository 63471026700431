@if(form){
<div [class]="selectedClass" [formGroup]="form">
  <div class="input-label" *ngIf="label">
    <label [ngClass]="{ tooltip: info.length > 0 }"
      >{{ label }}
      <img
        src="../../../assets/images/account/info.svg"
        alt="info"
        height="14px"
        width="14px"
        *ngIf="info.length > 0"
      />
    </label>
    <a
      (click)="handleLabelButtonClick()"
      *ngIf="labelButton"
      [ngClass]="
        canChange || form.get(controlName).value?.length > 0
          ? ''
          : 'input-label--disabled'
      "
      >{{ labelButton }}</a
    >
  </div>
  <a
    class="search-icon"
    [ngClass]="{ label: label }"
    *ngIf="searchIcon"
    (click)="handleSearchIconClick()"
  >
    <img
      src="../../../assets/images/search-icon.svg"
      alt="search icon"
      title="search icon"
      width="24px"
      height="24px"
    />
  </a>
  <input
    [type]="inputType"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
    [formControlName]="controlName"
    [pattern]="pattern"
    [name]="name"
    [maxlength]="maxLength"
    [minlength]="minLength"
    (blur)="onBlur()"
    (focus)="onFocus()"
    *ngIf="!isPhone && !isCreditCard && !isCreditCardDateYear && !isNumbersOnly"
    [id]="'input-' + controlName"
  />
  <input
    [type]="inputType"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
    [formControlName]="controlName"
    [pattern]="pattern"
    [maxlength]="maxLength"
    [minlength]="minLength"
    [name]="name"
    appPhoneMask
    *ngIf="isPhone"
    [id]="'input-' + controlName"
  />
  <input
    [type]="inputType"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
    [formControlName]="controlName"
    [pattern]="pattern"
    [maxlength]="maxLength"
    [minlength]="minLength"
    [name]="name"
    appCreditCardMask
    *ngIf="isCreditCard"
    [id]="'input-' + controlName"
  />
  <input
    [type]="inputType"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
    [formControlName]="controlName"
    [pattern]="pattern"
    [maxlength]="maxLength"
    [minlength]="minLength"
    [name]="name"
    appMonthYearMask
    *ngIf="isCreditCardDateYear"
    [id]="'input-' + controlName"
  />
  <input
    [type]="inputType"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
    [formControlName]="controlName"
    [pattern]="pattern"
    [maxlength]="maxLength"
    [minlength]="minLength"
    appNumbersOnly
    [name]="name"
    *ngIf="isNumbersOnly"
    [id]="'input-' + controlName"
  />
  <span
    class="error-message"
    *ngIf="
      form?.controls[controlName]?.touched &&
      form?.controls[controlName]?.errors
    "
  >
    <img
      src="../../../assets/images/invalid-icon.svg"
      alt="invalid icon"
      title="invalid icon"
      width="15px"
      height="13px"
    />
    <ng-container *ngIf="form?.controls[controlName].errors?.required">{{
      requiredErrorMessage
    }}</ng-container>
    <ng-container *ngIf="form?.controls[controlName].errors?.pattern">{{
      patternErrorMessage
    }}</ng-container>
    <ng-container *ngIf="form?.controls[controlName].errors?.email">{{
      emailErrorMessage
    }}</ng-container>
    <ng-container *ngIf="form?.controls[controlName].errors?.pastDate">{{
      pastDateErrorMessage
    }}</ng-container>
    <ng-container *ngIf="form?.controls[controlName].errors?.message">{{
      form?.controls[controlName].errors?.message
    }}</ng-container>
    <ng-container
      *ngIf="
        form?.controls[controlName].errors?.max ||
        form?.controls[controlName].errors?.maxlength
      "
      >{{ maxErrorMessage }}</ng-container
    >
    <ng-container
      *ngIf="
        form?.controls[controlName].errors?.minlength ||
        form?.controls[controlName].errors?.min
      "
      >{{ minErrorMessage }}</ng-container
    >
    <ng-container *ngIf="form?.controls[controlName].errors?.matching">{{
      matchingErrorMessage
    }}</ng-container>
  </span>
  <a
    (mousedown)="triggerFnc()"
    [class]="iconClass"
    *ngIf="
      !searchIcon &&
      !typeText &&
      form?.controls[controlName]?.value &&
      form?.controls[controlName]?.status !== 'DISABLED' &&
      isFocus
    "
  >
    <img
      [src]="imageSrc"
      alt="{{ inputType }} icon"
      title="{{ inputType }} icon"
    />
  </a>
  <span *ngIf="typeText" class="type-text">{{ typeText }}</span>
</div>
}@else {
<div [class]="selectedClass">
  <input
    [type]="inputType"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
    [name]="name"
    (input)="onChange($event)"
    [value]="value"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [id]="'input-' + name"
    required=""
  />
  <a (mousedown)="triggerFnc()" [class]="iconClass" *ngIf="value && isFocus">
    <img
      [src]="imageSrc"
      alt="{{ inputType }} icon"
      title="{{ inputType }} icon"
    />
  </a>
</div>
}
