import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-mini-cart-validation',
  templateUrl: './mini-cart-validation.component.html',
  styleUrls: ['./mini-cart-validation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MiniCartValidationComponent {
  @Output() validate = new EventEmitter<boolean>(false);
  opened: boolean = false;

  constructor() {}

  open() {
    this.opened = true;
  }

  backlink() {
    this.opened = false;
  }

  onBasketDeleted() {
    this.validate.emit(true);
    this.opened = false;
  }
}
