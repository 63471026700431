<div class="create-basket" *ngIf="opened">
  <div class="create-basket__header">
    <a (click)="backlink()">
      <img
        src="/assets/images/navigation/navigation-back-arrow.svg"
        width="16"
        height="16"
        alt="back"
        title="back"
      />
    </a>
    <h3 *ngIf="isEdit">Sepet düzenle</h3>
    <h3 *ngIf="!isEdit">Sepet oluştur</h3>
  </div>
  <div class="create-basket__container">
    <form [formGroup]="createBasketForm">
      <app-input-component
        label="Sepet adı"
        placeholder="Sepet adı giriniz"
        icon="clear"
        iconClass="clear"
        size="lg"
        [form]="createBasketForm"
        controlName="basketName"
      >
      </app-input-component>
    </form>
  </div>
  <div class="create-basket__footer">
    <app-link-component
      size="md"
      color="noborder"
      label="Vazgeç"
      (linkClicked)="backlink()"
    >
    </app-link-component>
    <app-link-component
      size="md"
      color="blue"
      label="Oluştur"
      (linkClicked)="save()"
    >
    </app-link-component>
  </div>
</div>
