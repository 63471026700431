export class AccountInfo {
  accountId: string;
  organizationId: string;
  firstName: string;
  lastName: string;
  gender: number;
  birthDate: Date | null;
  email: string;
  phoneNumber: string;
  emailPermission: boolean;
  smsPermission: boolean;
  callPermission: boolean;

  constructor() {
    this.accountId = '';
    this.organizationId = '';
    this.firstName = '';
    this.lastName = '';
    this.gender = 0;
    this.birthDate = null;
    this.email = '';
    this.phoneNumber = '';
    this.emailPermission = false;
    this.smsPermission = false;
    this.callPermission = false;
  }
}
