<div class="mini-cart-validation" *ngIf="opened">
  <div class="mini-cart-validation__header">
    <span (click)="backlink()">
      <img
        src="/assets/images/close.svg"
        width="14"
        height="14"
        alt="close"
        title="close"
      />
    </span>
  </div>
  <div class="mini-cart-validation__container">
    <ng-content></ng-content>
  </div>
  <div class="mini-cart-validation__footer">
    <app-link-component
      size="md"
      color="noborder"
      label="Vazgeç"
      (linkClicked)="backlink()"
    >
    </app-link-component>
    <app-link-component
      size="md"
      color="blue"
      label="Sil"
      (linkClicked)="onBasketDeleted()"
    >
    </app-link-component>
  </div>
</div>
