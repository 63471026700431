<div class="tab-button">
  <ng-container *ngFor="let segment of segments">
    <span
      [class]="segment.active ? 'active' : ''"
      (click)="onClick(segment.id)"
      *ngIf="!segment.hide"
    >
      {{ segment.display }} ({{ segment.count }})
    </span>
  </ng-container>
</div>
