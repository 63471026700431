<app-drawer
  [isOpen]="_isMiniCartOpen"
  [customHeader]="true"
  (isOpenChange)="close()"
>
  <div
    class="mini-cart__row"
    *ngIf="basketItemsSubject | async as _basketItems"
  >
    <div class="mini-cart__header shadow">
      <div class="mini-cart__header-title">
        <h3 *ngIf="selectedBasket?.status === 1">Teklif sepet özeti</h3>
        <h3 *ngIf="selectedBasket?.status !== 1">Sepet özeti</h3>
        <span (click)="close()">
          <img
            src="/assets/images/close.svg"
            width="14"
            height="14"
            alt="close"
            title="close"
          />
        </span>
      </div>
      <app-shopping-cart-select
        (selectChange)="onSelectBasket($event)"
        (basketDeleted)="deleteCart($event)"
        (newBasketEnabled)="createNewCart()"
      ></app-shopping-cart-select>
    </div>
    <div class="mini-cart__container" *ngIf="_basketItems.length > 0">
      <div class="mini-cart__cards">
        <app-message-box [errorModel]="offerMessage"></app-message-box>
        <app-minicart-item
          [items]="_basketItems"
          [selectedBasket]="selectedBasket"
          (removeItem)="onRemoveItemFromCart($event)"
          (productRouteRedirected)="productRouteRedirected()"
        ></app-minicart-item>
      </div>
    </div>
    <div class="mini-cart__container" *ngIf="_basketItems.length === 0">
      <div class="empty-cart">
        <p>Sepetinizde Ürün Yok</p>
        <img
          src="/assets/images/cart/empty-cart.svg"
          width="200"
          height="200"
          alt="empty cart"
          title="empty cart"
        />
      </div>
    </div>
    <div class="mini-cart__footer" *ngIf="selectedBasket">
      <div class="total-count">
        <p>
          Sepet toplamı
          <span
            class="old-price"
            *ngIf="
              selectedBasket.itemsTotalPrice >
              selectedBasket.discountedItemsTotalPrice
            "
            appPrice
            [price]="selectedBasket.itemsTotalPrice"
          ></span>
        </p>
        <span
          appPrice
          [price]="selectedBasket.discountedItemsTotalPrice"
        ></span>
      </div>
      <div class="cta">
        <app-link-component
          size="md"
          color="blue"
          label="Sepete git"
          (linkClicked)="routeCart()"
        ></app-link-component>
      </div>
    </div>
  </div>

  <app-initial-page
    #initialPage
    (createCart)="createNewCart()"
    (closeMiniCart)="close()"
  ></app-initial-page>
  <app-upsert-basket #upsertBasketComponent> </app-upsert-basket>

  <app-mini-cart-validation
    #deleteCartValidation
    (validate)="onBasketDeleted()"
  >
    <div class="delete-item-container">
      <h3>Sepet silme</h3>
      <p>
        Başka kullanıcılar tarafından sepete ürün eklendiği için sepet
        silinemez.
      </p>
      <p>İsterseniz kendi eklediğiniz ürünleri silebilirsiniz.</p>
    </div>
  </app-mini-cart-validation>

  <app-mini-cart-validation
    #deleteCartValidationAuthorized
    (validate)="onBasketDeleted()"
  >
    <div class="delete-item-container">
      <h3>Sepet silme</h3>
      <p>Sepeti silmek istediğinize emin misiniz?</p>
    </div>
  </app-mini-cart-validation>

  <app-mini-cart-validation
    #deleteProductValidation
    (validate)="deleteCartItem()"
  >
    <div class="delete-item-container">
      <h3>Ürün silme</h3>
      <p>Sadece kendi eklediğiniz ürünler silinecektir.</p>
    </div>
  </app-mini-cart-validation>
</app-drawer>
