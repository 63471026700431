import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Segment } from './model/segment';

@Component({
  selector: 'app-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss']
})
export class SegmentsComponent {
  @Input()
  segments: Segment[] = [];
  @Output() segmentChanged = new EventEmitter<number>();

  constructor() {}

  onClick(id: number) {
    this.segments.forEach((segment) => {
      if (id === segment.id) {
        segment.active = true;
      } else {
        segment.active = false;
      }
    });
    this.segmentChanged.emit(id);
  }
}
