import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { GeneralMessage } from '../../../../lib/core/models/general-message';
import { CustomToastrService } from '../../../../lib/core/services/custom-toastr.service';
import { ServiceBase } from '../../../../lib/core/services/service.base';
import { SignalRService } from '../../../../lib/core/services/signalR.service';
import { MiniCartComponent } from '../../../../lib/partials/mini-cart/mini-cart.component';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { AddItemRequest } from '../models/additem-request';
import { Basket, BasketStatus } from '../models/basket';
import { BasketSummary } from '../models/basket-summary';
import { MoveItemRequest } from '../models/moveitem-request';
import { RemoveItemRequest } from '../models/removeitem-request';

const basketBaseUrl = environment.apiUrl + '/api/basket';
const offerBaseUrl = environment.apiUrl + '/api/offer';

declare var window: any;

@Injectable({ providedIn: 'root' })
export class BasketService extends ServiceBase {
  constructor(
    http: HttpClient,
    signalRService: SignalRService,
    private authenticationService: AuthenticationService,
    private customToastrService: CustomToastrService
  ) {
    super(http, signalRService);

    authenticationService.onCredentialUpdated$.subscribe(() => this.update());
  }

  basketSummariesSubject = new BehaviorSubject<BasketSummary[]>([]);
  currentBasketSubject = new BehaviorSubject<Basket | undefined>(undefined);

  miniBasketComponent: MiniCartComponent | undefined = undefined;

  miniCartOpenSubject = new BehaviorSubject<boolean>(false);

  getBasketSummaries(): Observable<BasketSummary[]> {
    return new Observable<BasketSummary[]>((observer) => {
      this.authenticationService.getUserInfo().subscribe((accountInfo) => {
        if (accountInfo) {
          this.get<BasketSummary[]>(
            basketBaseUrl +
              '/summary?organizationId=' +
              accountInfo.organizationId
          ).subscribe((baskerSummaries) => {
            observer.next(baskerSummaries);
          });
        } else {
          observer.next(undefined);
        }
      });
    }).pipe(share());
  }

  createBasket(basketName: string) {
    return new Observable((observer) => {
      this.authenticationService.getUserInfo().subscribe((accountInfo) => {
        this.post(basketBaseUrl + '/create', {
          name: basketName,
          organizationId: accountInfo?.organizationId
        }).subscribe(() => {
          this.update();
          observer.next();
        });
      });
    }).pipe(share());
  }

  updateSelectedBasket(basketName: string) {
    let o = this.post(basketBaseUrl + '/update', {
      name: basketName
    }).pipe(share());

    o.subscribe(() => this.update());

    return o;
  }

  refreshBasket() {
    return this.get(basketBaseUrl + '/refresh').pipe(share());
  }

  deleteBasket(basketId: string) {
    let o = this.post(basketBaseUrl + '/delete/' + basketId, null).pipe(
      share()
    );

    o.subscribe(() => this.update());

    return o;
  }

  addItemToBasket(
    request: AddItemRequest,
    showMessage: boolean = true,
    showLink: boolean = true
  ) {
    return new Observable((observer) => {
      let message = new GeneralMessage();
      if (!this.authenticationService.isAuthorized()) {
        message.messageTitle = 'Ürün eklenemedi';
        message.messageText = 'Sepete ürün eklemek için giriş yapınız';
        message.messageLinkText = 'Giriş Yap';
        message.messageLinkUrl = '/auth/login';
        this.customToastrService.warning(message);
        return observer.next(undefined);
      }
      this.post(basketBaseUrl + '/additem', request).subscribe((res: any) => {
        if (res.addedItemsCount > 0 && showMessage) {
          message.messageTitle = 'Ürün sepete eklendi';
          if (showLink) {
            message.messageLinkText = 'Sepete git';
            message.messageLinkUrl = '/cart';
          }
          this.customToastrService.success(message);

          window['dataLayer'].push({
            event: 'add_to_basket',
            price: res.totalPrice / 4
          });
        } else if (res === 0) {
          message.messageTitle = 'Yeteri kadar ürün stokta yok';
          this.customToastrService.warning(message);
        }
        this.update();
        observer.next(res.addedItemsCount);
      });
    }).pipe(share());
  }

  removeItemsFromBasket(request: RemoveItemRequest) {
    let o = new Observable();
    if (this.currentBasketSubject.value?.status != BasketStatus.Active) {
      this.update();
      return o;
    }
    o = this.post(basketBaseUrl + '/removeitems', request).pipe(share());

    o.subscribe(() => this.update());

    return o;
  }

  getBasket(basketId: string): Observable<Basket> {
    return this.get<Basket>(basketBaseUrl + '/' + basketId).pipe(share());
  }

  applyDiscountCode(discountCode: string): Observable<boolean> {
    return this.post<boolean>(basketBaseUrl + '/applyDiscountCode', {
      discountCode: discountCode
    }).pipe(share());
  }

  removeDiscountCode() {
    return this.post(basketBaseUrl + '/removeDiscountCode', {}).pipe(share());
  }

  moveBasketItems(request: MoveItemRequest) {
    let o = this.post(basketBaseUrl + '/moveitems', request).pipe(share());

    o.subscribe(() => this.update());

    return o;
  }

  setCurrentBasketId(basketId: string): Observable<boolean> {
    return this.http
      .get<boolean>(basketBaseUrl + '/setcurrentbasketid/' + basketId)
      .pipe(share());
  }

  createOfferRequest(request: any) {
    return new Observable((observer) => {
      this.authenticationService.getUserInfo().subscribe((accountInfo) => {
        request.organizationId = accountInfo?.organizationId;

        this.post(offerBaseUrl, request).subscribe(() => {
          this.update();
          observer.next();
        });
      });
    }).pipe(share());
  }

  cancelOfferRequest(): Observable<any> {
    return new Observable((observer) => {
      this.authenticationService.getUserInfo().subscribe((accountInfo) => {
        this.get(offerBaseUrl + '/' + accountInfo?.organizationId).subscribe(
          () => {
            observer.next();
          }
        );
      });
    }).pipe(share());
  }

  update() {
    this.getBasketSummaries().subscribe((x: BasketSummary[]) => {
      this.basketSummariesSubject.next(x);

      if (x) {
        let filtered = x.filter((x) => x.selected);
        if (filtered.length) {
          this.getBasket(filtered[0].id).subscribe((b: Basket) => {
            // b.offerDate = filtered[0].offerDate;
            this.currentBasketSubject.next(b);
          });
        } else {
          this.currentBasketSubject.next(undefined);
        }
      }
    });
  }

  openMiniCart() {
    this.miniCartOpenSubject.next(true);
  }

  closeMiniCart() {
    this.miniCartOpenSubject.next(false);
  }
}
