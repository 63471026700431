import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { CoreModule } from '../../../../lib/core/core.module';
import { PartialsModule } from '../../../../lib/partials/partials.module';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { ContractComponent } from './footer/contract/contract.component';
import { CookiePolicyComponent } from './footer/contract/cookie-policy/cookie-policy.component';
import { CustomerInformationComponent } from './footer/contract/customer-information/customer-information.component';
import { PrivacyPolicyComponent } from './footer/contract/privacy-policy/privacy-policy.component';
import { RelevantPersonApplicationFormComponent } from './footer/contract/relevant-person-application-form/relevant-person-application-form.component';
import { ReturnConditionsComponent } from './footer/contract/return-conditions/return-conditions.component';
import { FooterComponent } from './footer/footer.component';
import { AccountPanelComponent } from './header/account-panel/account-panel.component';
import { LanguageModalComponent } from './header/account-panel/language-select/language-modal/language-modal.component';
import { LanguageSelectComponent } from './header/account-panel/language-select/language-select.component';
import { MyAccountMobileMenuComponent } from './header/account-panel/my-account/mobile-menu/my-account-mobile-menu.component';
import { MyAccountComponent } from './header/account-panel/my-account/my-account.component';
import { MyCartComponent } from './header/account-panel/my-cart/my-cart.component';
import { HeaderComponent } from './header/header.component';
import { SearchComponent } from './header/search/search.component';
import { MobileNavMenuItemComponent } from './navbar/mobile-nav-menu-item/mobile-nav-menu-item.component';
import { NavbarComponent } from './navbar/navbar.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    MobileNavMenuItemComponent,
    LanguageModalComponent,
    SearchComponent,
    AccountPanelComponent,
    LanguageSelectComponent,
    MyAccountComponent,
    MyAccountMobileMenuComponent,
    MyCartComponent,
    ContractComponent,
    CustomerInformationComponent,
    PrivacyPolicyComponent,
    ReturnConditionsComponent,
    RelevantPersonApplicationFormComponent,
    CookiePolicyComponent,
    CookieConsentComponent
  ],
  imports: [
    FormsModule,
    PartialsModule,
    ReactiveFormsModule,
    CommonModule,
    CoreModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    NgxPermissionsModule.forChild()
  ],
  providers: [],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    ReturnConditionsComponent,
    CookieConsentComponent
  ]
})
export class LayoutModule {}
