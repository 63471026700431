import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-item-counter',
  templateUrl: './item-counter.component.html',
  styleUrls: ['./item-counter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItemCounterComponent implements OnInit {
  @ViewChild('counterInput') counterInput: ElementRef | undefined;
  _counter: number = 1;
  @Input()
  counterType: any;

  @Input()
  set counter(value: number) {
    this._counter = value;
  }

  @Input() isCurrentValue: boolean = false;

  @Output()
  counterChange = new EventEmitter<number>();

  isFocus: boolean = false;

  @Input() hideIncreaseButton: boolean = false;
  
  @Input() disableIncrease: boolean = false;
  
  @Input() disableDecrease: boolean = false;

  constructor() {}

  onClickMinus() {
    if (this._counter <= 1) {
      return;
    }
    this.counterChange.emit(this.isCurrentValue ? +this._counter - 1 : -1);
  }

  onClickPlus() {
    this.counterChange.emit(this.isCurrentValue ? +this._counter + 1 : 1);
  }

  onInput() {}

  onEnter(event: any) {
    let changeValue = event.target.value - this.focusValue;
    if (event.target.value <= 0 || changeValue === 0) {
      event.target.blur();
      return;
    }

    event.target.blur();
  }

  onBlur(event: any) {
    this._counter = Number(this.focusValue);
    this.isFocus = false;
    let val = event.target.value;
    let res = val - this._counter;
    this.counterChange.emit(this.isCurrentValue ? val : res);
  }

  focusValue: number = 1;

  onFocus(event: any) {
    this.focusValue = event.target.value;
    this.isFocus = true;
  }

  ngOnInit(): void {  }

  setCounter(value: number) {
    this.counterInput!.nativeElement.value = value;
  }
}
