<div class="mc-items" *ngFor="let item of items; trackBy: trackByFn">
  @if( getItemCampaigns(item)){
  <div class="campaigns-wrapper">
    @for (campaing of getItemCampaigns(item); track campaing; let index =
    $index) {
    <div class="campaign">
      {{ campaing }}
    </div>
    }
  </div>
  }
  <div class="mc-items__top">
    <a
      (click)="goToProductDetail(item)"
      (click)="productRouteRedirected.emit(true)"
    >
      <div class="image">
        <img
          [src]="
            item.imageUrl
              ? fileUrl + '/minicart_img/' + item.imageUrl
              : '/assets/images/cart/item-image-not-found.svg'
          "
          width="48"
          height="48"
          [alt]="item.productTitle + 'image'"
          [title]="item.productTitle + 'image'"
        />
      </div>
      <div class="details">
        <span
          ><b>{{ item.brand }}</b> {{ item.productTitle }}
        </span>
        <span class="blue">{{ getVariantsView(item) }}</span>
      </div>
    </a>
    <a class="remove-item" (click)="removeCartItem(item)">
      <img
        src="/assets/images/wastebasket.svg"
        width="24"
        height="24"
        alt="wastebasket"
        title="wastebasket"
        *ngIf="checkDeletePermission(item)"
      />
    </a>
  </div>
  <div class="mc-items__bottom">
    <app-item-counter
      #itemCounter
      counterType="gray"
      [counter]="item.count"
      (counterChange)="onCounterChange($event, item)"
      [disableIncrease]="checkDisableIncrease(item)"
      [disableDecrease]="checkDisableDecrease(item)"
    ></app-item-counter>
    <div class="total-count">
      <p>Toplam</p>
      <div class="prices">
        <span
          class="old-price"
          *ngIf="item.total > getVariantTotalPrice(item)"
          appPrice
          [price]="item.total"
        ></span>
        <span>
          {{
            getVariantTotalPrice(item) | currency : 'TRY' : '' : '1.2-2'
          }}</span
        >
      </div>
    </div>
  </div>
</div>
