import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ServiceBase } from '../../../../lib/core/services/service.base';
import { SignalRService } from '../../../../lib/core/services/signalR.service';
import { Price } from '../../product/models/product-card';
import { PriceRequest } from '../models/price-request';

const stockBaseUrl = environment.apiUrl + '/api/price';

@Injectable({ providedIn: 'root' })
export class PriceService extends ServiceBase {
  constructor(http: HttpClient, signalRService: SignalRService) {
    super(http, signalRService);
  }

  showPrice$ = new BehaviorSubject<boolean>(false);

  setShowPrice(value: boolean) {
    this.showPrice$.next(value);
  }

  getPrice(request: PriceRequest): Observable<Price | undefined> {
    return this.post<Price | undefined>(stockBaseUrl, request).pipe(share());
  }
}
