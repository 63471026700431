import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
//import { AuthService } from 'ngx-auth';
import { NgxRolesService } from 'ngx-permissions';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../../../lib/core/services/utils.service';
import { AccountService } from '../../../domains/account/services/account.service';
import { AccessData } from '../models/access-data';
import { AccountSummary } from '../models/account-summary';
import { AuthorizeRequest } from '../models/authorize-request';
import { FirstRegister } from '../models/first-register';
import { ForgotPasswordRequest } from '../models/forgotpwd-request';
import { LoginRequest } from '../models/login-request';
import { RefreshTokenRequest } from '../models/refresh-token';
import { ResetPasswordRequest } from '../models/resetpwd-request';
import { SecondRegisterInfo } from '../models/second-register-info';
import { SecondRegisterWithPhoneEnd } from '../models/second-register-with-phone-end';
import { TokenStorage } from './token-storage.service';
declare var setTimeout: any;
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  API_URL = environment.apiUrl;
  IDENTITY_API_URL = environment.identityApiUrl;
  API_ENDPOINT_AUTHORIZE = '/connect/authorize';
  API_ENDPOINT_LOGIN = '/connect/token';
  API_ENDPOINT_LOGOUT = '/connect/logout';
  API_ENDPOINT_CHANGEPASSWORD = '/connect/changepassword';
  API_ENDPOINT_FORGOTPASSWORD = '/connect/forgotpassword';
  API_ENDPOINT_FIRST_REGISTER = '/connect/first-register';
  API_ENDPOINT_SECOND_REGISTER = '/connect/second-register';
  API_ENDPOINT_SECOND_REGISTER_WITH_PHONE_BEGIN =
    '/connect/begin-second-register-with-phone';
  API_ENDPOINT_SECOND_REGISTER_WITH_PHONE_END =
    '/connect/end-second-register-with-phone';
  API_ENDPOINT_RESETPASSWORD = '/connect/resetpassword';
  API_ENDPOINT_REFRESH = '/connect/refresh';
  API_ENDPOINT_RESET_PASSWORD_BY_CODE = '/connect/resetpasswordbycode';

  public onCredentialUpdated$: Subject<AccountSummary | undefined>;

  constructor(
    private http: HttpClient,
    private roleService: NgxRolesService,
    private tokenStorage: TokenStorage,
    private util: UtilsService,
    private router: Router,
    private accountService: AccountService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.onCredentialUpdated$ = new Subject();
  }

  /**
   * Check, if user already authorized.
   * @description Should return Observable with true or false values
   * @returns {Observable<boolean>}
   * @memberOf AuthService
   */
  public isAuthorized(): boolean {
    return !!this.tokenStorage.getAccessToken();
  }

  /**
   * Get access token
   * @description Should return access token in Observable from e.g. localStorage
   * @returns {string}
   */
  public getAccessToken(): string {
    return this.tokenStorage.getAccessToken();
  }

  /**
   * Get user roles
   * @returns {Observable<any>}
   */
  public getUserRoles(): Observable<any> {
    return this.tokenStorage.getUserRoles();
  }

  /**
   * Get user profile info
   * @returns {Observable<any>}
   */
  public getUserInfo(): Observable<AccountSummary> {
    return new Observable<AccountSummary>((observer) => {
      if (!this.isAuthorized()) {
        observer.next(undefined);
        return;
      }

      var info = this.tokenStorage.getUserInfo();

      if (info) {
        observer.next(info);
      } else {
        this.accountService.getAccountSummary().subscribe((data: any) => {
          let accountSummary = <AccountSummary>{
            firstName: data.firstName,
            lastName: data.lastName,
            accountId: data.accountId,
            email: data.email,
            phoneNumber: data.phoneNumber,
            organizationId: data.organizationId,
            organizationName: data.organizationName,
            permissions: data.permissions,
            roles: data.roles
          };
          this.tokenStorage.setUserInfo(accountSummary);
          this.tokenStorage.setUserRoles(data.roles);
          this.onCredentialUpdated$.next(accountSummary);

          observer.next(accountSummary);
        });
      }
    });
  }

  /**
   * Get user profile info
   * @returns {Observable<any>}
   */
  public setUserInfo(info: AccountSummary) {
    this.tokenStorage.setUserInfo(info);
  }

  public refreshToken(refreshToken: string): Observable<any> {
    if (
      !refreshToken ||
      refreshToken === '' ||
      refreshToken === 'undefined' ||
      refreshToken === 'null'
    ) {
      this.logout(true);
      return throwError('Refresh token is not valid');
    }

    let refreshTokenRequest = new RefreshTokenRequest(refreshToken);

    return this.http
      .post<AccessData>(
        this.API_URL + this.API_ENDPOINT_REFRESH,
        this.util.urlParam(refreshTokenRequest),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse): Observable<any> => {
          this.logout(true);
          return throwError(error);
        }),
        share(),
        map((result: any) => {
          if (result instanceof Array) {
            return result.pop();
          }
          return result;
        }),
        tap(this.saveAccessData.bind(this)),
        tap(() => this.getUserInfo().subscribe())
      );
  }

  public authorize(loginRequest: AuthorizeRequest): Observable<boolean> {
    this.tokenStorage.clear();
    let self = this;
    return new Observable<boolean>((observer) => {
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (
          this.status === 200 &&
          this.readyState === XMLHttpRequest.HEADERS_RECEIVED
        ) {
          let url = xhr.responseURL;
          xhr.abort();
          if (url.includes('code=')) {
            setTimeout(() => {
              self.document.location.href = url;
            }, 0);
            observer.next(true);
          } else {
            observer.next(false);
          }
          observer.complete();
        }
      };
      let url = this.IDENTITY_API_URL + this.API_ENDPOINT_AUTHORIZE;
      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.send(this.util.urlParam(loginRequest));
    }).pipe(share());
  }

  /**
   * Verify that outgoing request is refresh-token,
   * so interceptor won't intercept this request
   * @param {string} url
   * @returns {boolean}
   */
  // public verifyTokenRequest(url: string): boolean {
  //   return url.endsWith(this.API_ENDPOINT_REFRESH);
  // }

  /**
   * Submit login request
   * @param {LoginRequest} loginRequest
   * @returns {Observable<any>}
   */
  public login(loginRequest: LoginRequest): Observable<any> {
    this.tokenStorage.clear();

    return this.http
      .post<AccessData>(
        this.API_URL + this.API_ENDPOINT_LOGIN,
        this.util.urlParam(loginRequest),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      .pipe(
        share(),
        map((result: any) => {
          if (result instanceof Array) {
            return result.pop();
          }
          return result;
        }),
        tap(this.saveAccessData.bind(this)),
        tap(() => {
          this.getUserInfo().subscribe(() => {
            this.router.navigate(['/']);
          });
        })
        // catchError(this.handleError('login', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError() {
    return (error: any): Observable<any> => {
      // console.log('error');
      // console.log(JSON.stringify(error));
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return throwError(error);
    };
  }

  /**
   * Logout
   */
  public logout(refresh?: boolean): void {
    this.tokenStorage.clear();
    this.roleService.flushRoles();
    this.onCredentialUpdated$.next(undefined);

    this.http.get(this.IDENTITY_API_URL + this.API_ENDPOINT_LOGOUT).subscribe();

    if (refresh) {
      this.router.navigate(['/']);
    }
  }

  /**
   * Save access data in the storage
   * @private
   * @param {AccessData} data
   */
  private saveAccessData(accessData: AccessData) {
    if (typeof accessData !== 'undefined') {
      this.tokenStorage.clear();
      this.roleService.flushRoles();

      this.tokenStorage.setAccessToken(accessData.accessToken);
      this.tokenStorage.setRefreshToken(accessData.refreshToken);
    }

    return undefined;
  }

  public firstRegister(body: FirstRegister): Observable<any> {
    return this.http
      .post(this.IDENTITY_API_URL + this.API_ENDPOINT_FIRST_REGISTER, body)
      .pipe(catchError(this.handleError()))
      .pipe(share());
  }

  public secondRegister(model: SecondRegisterInfo): Observable<any> {
    return this.http
      .post(this.IDENTITY_API_URL + this.API_ENDPOINT_SECOND_REGISTER, model)
      .pipe(catchError(this.handleError()))
      .pipe(share());
  }

  public secondRegisterWithPhoneBegin(
    model: SecondRegisterInfo
  ): Observable<any> {
    return this.http
      .post(
        this.IDENTITY_API_URL +
          this.API_ENDPOINT_SECOND_REGISTER_WITH_PHONE_BEGIN,
        model
      )
      .pipe(catchError(this.handleError()))
      .pipe(share());
  }

  public secondRegisterWithPhoneEnd(
    model: SecondRegisterWithPhoneEnd
  ): Observable<any> {
    return this.http
      .post(
        this.IDENTITY_API_URL +
          this.API_ENDPOINT_SECOND_REGISTER_WITH_PHONE_END,
        model
      )
      .pipe(catchError(this.handleError()))
      .pipe(share());
  }

  public changePassword(data: any): Observable<any> {
    return this.http
      .post(this.IDENTITY_API_URL + this.API_ENDPOINT_CHANGEPASSWORD, data)
      .pipe(share());
  }

  public forgotPassword(
    forgotPassword: ForgotPasswordRequest
  ): Observable<any> {
    return this.http
      .post(this.IDENTITY_API_URL + this.API_ENDPOINT_FORGOTPASSWORD, {
        ...forgotPassword,
        redirectTo: environment.webSite + '/auth/new-password'
      })
      .pipe(share());
  }

  public resetPasswordByCode(code: string): Observable<any> {
    return this.http
      .post(this.IDENTITY_API_URL + this.API_ENDPOINT_RESET_PASSWORD_BY_CODE, {
        code: code
      })
      .pipe(share());
  }

  public resetPassword(model: ResetPasswordRequest): Observable<any> {
    return this.http
      .post(this.IDENTITY_API_URL + this.API_ENDPOINT_RESETPASSWORD, model)
      .pipe(share());
  }

  public sendPasswordResetCode(phoneNumber: string): Observable<any> {
    //TODO: Böyle bir endpoint yok
    console.log(phoneNumber);
    return new Observable();
  }
}
