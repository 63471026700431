import { Injectable } from '@angular/core';
declare let localStorage: any;

class CustomLocalStorage {
  data: { [key: string]: string } = {};

  setItem(key: string, value: string): void {
    this.data[key] = value;
  }

  getItem(key: string): string {
    return this.data[key];
  }

  removeItem(key: string): void {
    delete this.data[key];
  }

  clear(): void {
    this.data = {};
  }
}
@Injectable({ providedIn: 'root' })
export class StorageService {
  private customLocalStorage: CustomLocalStorage = new CustomLocalStorage();
  private storage: any;
  public isLocalStorageAvailable: boolean = false;

  constructor() {
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      this.storage = localStorage;
      this.isLocalStorageAvailable = true;
    } catch (e) {
      this.storage = this.customLocalStorage;
      this.isLocalStorageAvailable = false;
    }
  }

  setItem<T>(key: string, obj: T) {
    let val: string = JSON.stringify(obj);
    this.storage.setItem(key, val);
  }

  setStringItem(key: string, value: string) {
    this.storage.setItem(key, value);
  }

  getItem(key: string): any {
    const value: any = this.storage.getItem(key) || 'null';
    return JSON.parse(value);
  }

  getStringItem(key: string): string {
    const value: any = this.storage.getItem(key);
    return value;
  }

  removeItem(key: string) {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }
}
