import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPermissionsModule } from 'ngx-permissions';

import { CoreModule } from '../../core/core.module';
import { AccordionComponent } from '../accordion/accordion.component';
import { AccordionItemComponent } from '../accordion/item/accordion-item.component';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { BadgeComponent } from './badge/badge.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { InputComponent } from './input/input.component';
import { ItemCounterComponent } from './item-counter/item-counter.component';
import { LinkComponent } from './link/link.component';
import { ModalComponent } from './modal/modal.component';
import { ModalWideComponent } from './modal-wide/modal-wide.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PasswordStrengthMeterComponent } from './password-strength-meter/password-strength-meter.component';
import { PhoneComponent } from './phone/phone.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { RatingInputComponent } from './rating-input/rating-input.component';
import { SegmentsComponent } from './segments/segments.component';
import { SelectComponent } from './select/select.component';
import { StepperComponent } from './stepper/stepper.component';
import { SwitchComponent } from './switch/switch.component';
import { TabsComponent } from './tabs/tabs.component';
import { TextareaComponent } from './textarea/textarea.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlatpickrModule.forRoot(),
    NgSelectModule,
    NgxPaginationModule,
    CoreModule,
    NgxPermissionsModule.forChild()
  ],
  exports: [
    InputComponent,
    SelectComponent,
    TextareaComponent,
    DatepickerComponent,
    RadioGroupComponent,
    PhoneComponent,
    TabsComponent,
    NgxPaginationModule,
    PasswordStrengthMeterComponent,
    LinkComponent,
    ItemCounterComponent,
    ModalComponent,
    SegmentsComponent,
    StepperComponent,
    PaginationComponent,
    ModalWideComponent,
    AccordionComponent,
    BadgeComponent,
    AccordionItemComponent,
    SwitchComponent,
    RatingInputComponent,
    BackToTopComponent,
    CheckboxComponent
  ],
  declarations: [
    InputComponent,
    SelectComponent,
    TextareaComponent,
    DatepickerComponent,
    RadioGroupComponent,
    PhoneComponent,
    AccordionComponent,
    AccordionItemComponent,
    BadgeComponent,
    TabsComponent,
    PasswordStrengthMeterComponent,
    LinkComponent,
    ItemCounterComponent,
    ModalComponent,
    ModalWideComponent,
    SegmentsComponent,
    StepperComponent,
    PaginationComponent,
    BadgeComponent,
    SwitchComponent,
    RatingInputComponent,
    BackToTopComponent,
    CheckboxComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TemplateModule {}
