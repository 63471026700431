import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SignalRService } from './signalR.service';

export abstract class ServiceBase {
  constructor(
    public http: HttpClient,
    private signalRService: SignalRService
  ) {}

  private wrapSocketObservable<T>(
    observable: Observable<string>
  ): Observable<T> {
    let responseId: any = undefined;
    let subscriber: any = undefined;

    let response = new Observable<T>((_subscriber) => {
      subscriber = _subscriber;

      if (responseId) {
        let responseData = this.signalRService.responses[responseId];

        if (typeof responseData !== 'undefined') {
          _subscriber.next(responseData);

          delete this.signalRService.responses[responseId];
        } else {
          this.signalRService.subscribers[responseId] = _subscriber;
        }
      }
    });

    observable.subscribe((_responseId) => {
      responseId = _responseId;
      if (subscriber) {
        let responseData = this.signalRService.responses[responseId];

        if (typeof responseData !== 'undefined') {
          subscriber.next(responseData);

          delete this.signalRService.responses[responseId];
        } else {
          this.signalRService.subscribers[responseId] = subscriber;
        }
      }
    });

    return response.pipe(share());
  }

  post<T>(url: string, postData: any): Observable<T> {
    if (environment.socketEnabled) {
      let observable = this.http.post<string>(url, postData, {
        // headers: new HttpHeaders({
        //   clientId: this.signalRService.connection?.connectionId as string
        // })
      });

      return this.wrapSocketObservable(observable);
    }

    return this.http.post<T>(url, postData);
  }

  get<T>(url: string, enableCaching: boolean = false): Observable<T> {
    if (enableCaching) {
      return this.http.get<T>(url);
    }
    if (url.indexOf('?') > -1) {
      let peaces = url.split('?');

      if (peaces[1].length) {
        url = peaces[0] + '?' + peaces[1] + '&v=' + Math.random();
      } else {
        url = peaces[0] + '?v=' + Math.random();
      }
    } else {
      url += '?v=' + Math.random();
    }

    if (environment.socketEnabled) {
      var observable = this.http.get<string>(url, {
        // headers: new HttpHeaders({
        //   clientId: this.signalRService.connection?.connectionId as string
        // })
      });

      return this.wrapSocketObservable(observable);
    }

    return this.http.get<T>(url);
  }
}
