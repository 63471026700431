export interface Basket {
  id: string;
  name: string;
  organizationId: string;
  accountId: string;
  options: Option[];
  offerDate: Date;
  offerAcceptDate: null;
  offerDeadline: null;
  status: number;
  discountCode: string;
  itemsTotalPrice: number;
  itemsTotalPriceCampaignTitle: null;
  itemsTotalPriceCampaignDiscount: null;
  itemsTotalPriceCampaignDiscountRate: null;
  discountedItemsTotalPrice: number;
  cargoPrice: number;
  cargoPriceCampaignTitle: null;
  cargoPriceCampaignDiscount: null;
  cargoPriceCampaignDiscountRate: null;
  discountedCargoPrice: number;
  totalPrice: number;
  discountedTotalPrice: number;
}
export interface Option {
  productId: string;
  productTitle: string;
  brand: string;
  imageUrl: string;
  variants: Variant[];
  items: Item[];
  currentAccountContainItems: boolean;
}
export interface Item {
  price: number;
  discountedPrice: number;
  currencyCode: string;
  countryCode: string;
  accountId: string;
  accountFirstName: string;
  accountLastName: string;
  shippingTime: number;
  campaignTitle: string;
  campaignDiscount: null;
  campaignDiscountRate: number;
}
export interface Variant {
  attributeId: string;
  attributeItemId: string;
  attributeName: string;
  attributeItemName: string;
}
export class BasketItem {
  price: number = 0;
  accountId: string = '';
  accountFirstName: string = '';
  accountLastName: string = '';
  discountedPrice: number = 0;
  shippingTime: number = 0;
  currencyCode: string = '';
  campaignTitle: string = '';
  campaignDiscount: number = 0;
  campaignDiscountRate: number = 0;
}

export enum BasketStatus {
  Active = 0,
  OfferWaiting = 1,
  OfferAccepted = 2,
  OfferCanceled = 3,
  OfferTimeout = 4,
  OfferRejected = 5
}
