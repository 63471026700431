import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { StorageService } from '../../../../lib/core/services/storage.service';
import { AccountSummary } from '../models/account-summary';

@Injectable({ providedIn: 'root' })
export class TokenStorage {
  storageService = inject(StorageService);

  /**
   * Get access token
   * @returns {Observable<string>}
   */
  public getAccessToken(): string {
    return this.storageService.getStringItem('accessToken');
  }

  /**
   * Get refresh token
   * @returns {Observable<string>}
   */
  public getRefreshToken(): string {
    return this.storageService.getStringItem('refreshToken');
  }

  /**
   * Get user roles in JSON string
   * @returns {Observable<any>}
   */
  public getUserRoles(): Observable<any> {
    const roles: any = this.storageService.getItem('userRoles');
    return of(roles);
  }

  /**
   * Set access token
   * @returns {TokenStorage}
   */
  public setAccessToken(token: string): TokenStorage {
    this.storageService.setStringItem('accessToken', token);

    return this;
  }

  /**
   * Set refresh token
   * @returns {TokenStorage}
   */
  public setRefreshToken(token: string): TokenStorage {
    this.storageService.setStringItem('refreshToken', token);

    return this;
  }

  /**
   * Set user roles
   * @param roles
   * @returns {TokenStorage}
   */
  public setUserRoles(roles: any): any {
    if (roles != null) {
      this.storageService.setItem('userRoles', roles);
    }

    return this;
  }

  public setUserInfo(info: AccountSummary): any {
    if (info != null) {
      this.storageService.setItem('userInfo', info);
    }

    return this;
  }

  public getUserInfo(): AccountSummary {
    const userInfo: any = this.storageService.getItem('userInfo');

    return userInfo;
  }

  public getRememberData() {
    const rememberInfo: any = this.storageService.getItem('rememberInfo');

    return rememberInfo;
  }

  public setRememberData(remember: boolean, username: string) {
    this.storageService.setItem('rememberInfo', {
      remember,
      username
    });
  }

  /**
   * Remove tokens
   */
  public clear() {
    this.storageService.removeItem('accessToken');
    this.storageService.removeItem('refreshToken');
    this.storageService.removeItem('userInfo');
    this.storageService.removeItem('userRoles');
  }
}
