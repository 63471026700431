<div
  class="message-box message-box--{{ errorModel?.messageType || type }}"
  *ngIf="errorModel || type"
>
  <div class="icon">
    <img
      src="/assets/images/auth/message-box-{{
        errorModel?.messageType || type
      }}.svg"
      alt="{{ errorModel?.messageType || type }} icon"
      title="{{ errorModel?.messageType || type }} icon"
      [width]="errorModel?.size === 'lg' ? 20 : 13"
      [height]="errorModel?.size === 'lg' ? 20 : 13"
    />
  </div>
  <div class="text">
    <p *ngIf="errorModel?.messageTitle || messageTitle">
      {{ errorModel?.messageTitle || messageTitle }}
    </p>
    <div class="message-text" *ngIf="errorModel?.messageText || message">
      {{ errorModel?.messageText || message }}
    </div>
    <a
      href="{{ errorModel?.messageLinkUrl }}"
      *ngIf="errorModel?.messageLinkText?.length"
      >{{ errorModel?.messageLinkText }}</a
    >
    <div class="message-text">
      <ng-content></ng-content>
    </div>
  </div>
</div>
