import { Component, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { environment } from '../../environments/environment';
import { AclService } from '../../lib/core/services/acl.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'izyget-ui';
  showSplashScreen: boolean = true;
  constructor(aclService: AclService, meta: Meta) {
    aclService.init();
    meta.addTag({
      name: 'robots',
      content: environment.production ? 'index, follow' : 'noindex'
    });
  }
}
