import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, inject, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appPrice]'
})
export class PriceDirective implements OnChanges {
  @Input() price: number | undefined = undefined;
  @Input() currency: string = 'TRY';

  el = inject(ElementRef);
  currencyPipe = inject(CurrencyPipe);
  ngOnChanges(): void {
    this.formatPrice();
  }

  formatPrice() {
    if (typeof this.price !== 'number') {
      return;
    }

    let formattedPrice: string = '';
    switch (this.currency) {
      case 'TRY':
        formattedPrice += '₺ ';
        break;
      case 'USD':
        formattedPrice += '$ ';
        break;
      case 'EUR':
        formattedPrice += '€ ';
        break;
      default:
        formattedPrice += '₺ ';
        break;
    }
    formattedPrice += this.currencyPipe.transform(
      this.price,
      this.currency,
      '',
      '1.2-2'
    );

    this.el.nativeElement.innerText = formattedPrice;
  }
}
