<div #splashScreen *ngIf="showSplashScreen" class="m-splash-screen">
  <img src="../assets/images/splash.svg" width="400" height="157" />
  <div class="snippet" data-title=".dot-pulse">
    <div class="stage">
      <div class="dot-pulse"></div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
