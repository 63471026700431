import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../../../lib/domains/auth/services/authentication.service';
import { TokenStorage } from '../../../lib/domains/auth/services/token-storage.service';

/** Pass untouched request through to the next request handler. */
@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private tokenStorage: TokenStorage,
    private http: HttpClient
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = this.tokenStorage.getAccessToken();
    if (token) {
      req = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + this.tokenStorage.getAccessToken()
        )
      });
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse): Observable<any> => {
        if (error && (error as HttpErrorResponse).status === 401) {
          return this.authenticationService
            .refreshToken(this.tokenStorage.getRefreshToken())
            .pipe(switchMap(() => this.http.request(req)));
        }

        return throwError(error);
      })
    );
  }
}
