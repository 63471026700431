import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { share } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { ServiceBase } from '../../../../../lib/core/services/service.base';
import { SignalRService } from '../../../../../lib/core/services/signalR.service';
import { Menu } from '../../../content/layout/navbar/navbar.models';
import { PromotedProduct } from '../../../content/pages/home/promoted-product/promoted-product';
import { Brand } from '../models/brand';
import { Category } from '../models/category';
import { OptionInfo } from '../models/option-info';
import { OptionInfoRequest } from '../models/option-info-request';
import { Tag } from '../models/tag';

const getCMSUrl = environment.apiUrl + '/api/cms';

@Injectable({ providedIn: 'root' })
export class CMSService extends ServiceBase {
  topSellingCategory: WritableSignal<Category[]> = signal([]);

  constructor(http: HttpClient, signalRService: SignalRService) {
    super(http, signalRService);
    this.getTopSellingCategories();
  }

  getMenu() {
    return this.get<Menu[]>(getCMSUrl + '/menu', true).pipe(share());
  }

  getOptionInfo(key: string) {
    return this.get<OptionInfo>(getCMSUrl + '/optioninfo/' + key).pipe(share());
  }

  getOptionDetailInfo(body: OptionInfoRequest) {
    return this.post<OptionInfo>(getCMSUrl + '/optioninfo', body).pipe(share());
  }

  getBrands() {
    return this.get<Brand[]>(getCMSUrl + '/brands', true).pipe(share());
  }

  getTags() {
    return this.get<Tag[]>(getCMSUrl + '/tags', true).pipe(share());
  }

  getCategoryPageInfo(category1: string, category2: string, category3: string) {
    let path = [category1, category2, category3]
      .filter((x) => x !== undefined && x !== null)
      .join('/');
    return this.get(getCMSUrl + '/category/pageinfo/' + path).pipe(share());
  }

  getCategorySearchPageInfo(category: string) {
    return this.get(getCMSUrl + '/category-search/pageinfo/' + category).pipe(
      share()
    );
  }

  getBrandPageInfo(url: string) {
    return this.get<Brand>(getCMSUrl + '/brand/pageinfo/' + url).pipe(share());
  }

  getTagPageInfo(key: string) {
    return this.get<Tag>(getCMSUrl + '/tag/pageinfo/' + key).pipe(share());
  }

  getTopSellingCategories() {
    this.get<Category[]>(getCMSUrl + '/category/topselling', true).subscribe(
      (categories) => {
        this.topSellingCategory.set(categories);
      }
    );
  }

  getMainSlider() {
    return this.get<PromotedProduct[]>(getCMSUrl + '/main-slider', true).pipe(
      share()
    );
  }
}
