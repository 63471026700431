import {
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

import { environment } from '../../../../environments/environment';
import { OptionInfoRequest } from '../../../../izyget-ui/app/domains/cms/models/option-info-request';
import { CMSService } from '../../../../izyget-ui/app/domains/cms/services/cms.service';
import { BasketStatus } from '../../../domains/basket/models/basket';
import { BasketSummary } from '../../../domains/basket/models/basket-summary';
import {
  BasketItemSummary,
  BasketItemVariant
} from '../../../domains/basket/models/basketitem-summary';
import { BasketService } from '../../../domains/basket/services/basket.service';
import { ItemCounterComponent } from '../../template/item-counter/item-counter.component';

@Component({
  selector: 'app-minicart-item',
  templateUrl: './minicart-item.component.html',
  styleUrls: ['./minicart-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MinicartItemComponent {
  @Input() items: BasketItemSummary[] = [];
  @Input() disable: boolean = false;
  @Input() selectedBasket: BasketSummary | undefined;
  @Output() removeItem = new EventEmitter<BasketItemSummary>();
  @Output() productRouteRedirected = new EventEmitter<boolean>();
  @ViewChild('itemCounter') itemCounter: ItemCounterComponent | undefined;

  @HostBinding('class')
  classes = 'minicart-wrapper';
  fileUrl = environment.fileApiUrl+ '/product-images';
  private cmsService = inject(CMSService);
  private router = inject(Router);
  props: string = '';
  constructor(
    private basketService: BasketService,
    private permissionService: NgxPermissionsService
  ) {}

  removeCartItem(event: BasketItemSummary) {
    this.removeItem.emit(event);
  }

  getItemCampaigns(item: BasketItemSummary) {
    return [
      ...new Set(
        item.priceItems
          .map((x) => x.campaignTitle)
          .filter((x) => x !== undefined)
      )
    ];
  }

  getVariantTotalPrice(item: BasketItemSummary) {
    return item.priceItems.reduce(
      (acc, price) => acc + price.discountedPrice,
      0
    );
  }

  onCounterChange(count: number, item: BasketItemSummary) {
    if (count > 0) {
      this.basketService
        .addItemToBasket({
          productId: item.productId,
          countryCode: 'TR',
          currencyCode: 'TRY',
          quantity: count,
          attributeItemIds: item.variants.map((x) => x.attributeItemId)
        })
        .subscribe((res) => {
          if (res === 0) {
            this.itemCounter?.setCounter(item.count);
          }
        });
    } else if (count < 0) {
      this.basketService
        .removeItemsFromBasket({
          productId: item.productId,
          attributeItemIds: item.variants.map((x) => x.attributeItemId),
          quantity: Math.abs(count)
        })
        .subscribe();
    }
  }

  getVariantsView(option: BasketItemSummary) {
    return option.variants
      .map((x: BasketItemVariant) => x.attributeItemName)
      .join(' / ');
  }

  getVariants(item: BasketItemSummary) {
    return item.variants.map((x) => x.attributeItemId);
  }

  checkDeletePermission(item: BasketItemSummary) {
    return (
      !!this.permissionService.getPermission('DELETE_BASKET') || !item.disabled
    );
  }

  checkDisableIncrease(item: BasketItemSummary) {
    return (
      item.disabled ||
      !(
        this.selectedBasket?.status === BasketStatus.Active ||
        this.selectedBasket?.status === BasketStatus.OfferAccepted
      )
    );
  }

  checkDisableDecrease(item: BasketItemSummary) {
    return item.disabled || this.selectedBasket?.status != BasketStatus.Active;
  }

  trackByFn(index: number, item: BasketItemSummary) {
    return item._id;
  }

  getVariantsValues(item: BasketItemSummary) {
    return item.variants
      .map((x) => x.attributeItemName)
      .sort((a, b) => a.localeCompare(b))
      .join('-');
  }

  goToProductDetail(item: BasketItemSummary) {
    let body: OptionInfoRequest = {
      productId: item.productId,
      variantIds: item.variants.map((x) => x.attributeItemId)
    };
    this.cmsService.getOptionDetailInfo(body).subscribe((res) => {
      this.router.navigateByUrl(`/product-detail/${res.url}/${res.id}`);
    });
  }
}
