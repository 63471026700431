import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MobileVisibilityDirective } from './directives/app-mobil-visibility.directive';
import { PriceDirective } from './directives/app-price.directive';
import { TabletVisibilityDirective } from './directives/app-tablet-visibility.directive';
import { WebVisibilityDirective } from './directives/app-web-visibility.directive';
import { CreditCardMaskDirective } from './directives/credit-card-mask.directive';
import { MonthYearMaskDirective } from './directives/month-year-mask.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { ActivityTypeClassPipe } from './models/pipes/activity-type-class.pipe';
import { PackageStatusPipe } from './models/pipes/package-status.pipe';
import { WeightSizeTypePipe } from './models/pipes/weight-size-type.pipe';
import { ConsoleLogPipe } from './pipes/console-log.pipe';
import { FilterPipe } from './pipes/filter-pipe';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ArraySortPipe } from './pipes/sort.pipe';
import { ShortNamePipe } from './pipes/sort-name.pipe';
import { NameMaskPipe } from './pipes/name-mask.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    // directives
    MobileVisibilityDirective,
    WebVisibilityDirective,
    TabletVisibilityDirective,
    CreditCardMaskDirective,
    MonthYearMaskDirective,
    NumbersOnlyDirective,
    PhoneMaskDirective,
    PriceDirective,
    // pipes
    FirstLetterPipe,
    ShortNamePipe,
    ArraySortPipe,
    JoinPipe,
    ConsoleLogPipe,
    SafePipe,
    PackageStatusPipe,
    ActivityTypeClassPipe,
    WeightSizeTypePipe,
    NameMaskPipe,
    FilterPipe
    // DatePipe,
    // CurrencyPipe
  ],
  exports: [
    // directives
    MobileVisibilityDirective,
    WebVisibilityDirective,
    TabletVisibilityDirective,
    CreditCardMaskDirective,
    MonthYearMaskDirective,
    NumbersOnlyDirective,
    PhoneMaskDirective,
    PriceDirective,
    // pipes
    FirstLetterPipe,
    ShortNamePipe,
    ArraySortPipe,
    JoinPipe,
    ConsoleLogPipe,
    SafePipe,
    PackageStatusPipe,
    ActivityTypeClassPipe,
    WeightSizeTypePipe,
    NameMaskPipe,
    FilterPipe
    // DatePipe,
    // CurrencyPipe
  ],
  providers: []
})
export class CoreModule {}
