export const environment = {
  production: false,
  apiUrl: 'https://api-test.dtasistan.com',
  identityApiUrl: 'https://identityapi-test.dtasistan.com',
  recaptchaKey: '6LcRLsIpAAAAALS4iOMcOGenapq5gru3zmsL50v7',
  fileApiUrl: 'https://api-test.dtasistan.com/api/file/global/product-images',
  socketEnabled: false,
  priceEnabledWithoutLogin: true,
  webSite: 'https://test.dtasistan.com'
};
