import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appMonthYearMask]'
})
export class MonthYearMaskDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    this.onInputChange(event);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) {
    this.onInputChange(event.target.value);
  }

  onInputChange(event?: any) {
    if (event == null) {
      return '';
    }
    let newVal = event.replace(/\D/g, '');
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 2) {
      if (newVal > 12 || newVal < 0) {
        newVal = '';
      } else {
        newVal = newVal.replace(/^(\d{0,2})/, '$1');
      }
    } else {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
    }

    if (newVal === '') {
      this.ngControl.control?.setValue(null);
    } else {
      this.ngControl.valueAccessor?.writeValue(newVal);
    }
    return newVal;
  }
}
