export class GeneralMessage {
  messageType?: any;
  messageText?: any;
  messageTitle?: any;
  messageLinkText?: any;
  messageLinkUrl?: any;
  size?: 'md' | 'lg' = 'lg';

  constructor(messageTitle?: any, messageText?: any) {
    this.messageText = messageText;
    this.messageTitle = messageTitle;
  }
}
