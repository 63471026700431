export class OrganizationInfo {
  organizationId: string;
  name: string;
  email: string;
  landPhoneNumber: string;
  mobilePhoneNumber: string;
  vkn: string;
  taxOffice: string;
  imageUrl: string;

  constructor() {
    this.organizationId = '';
    this.name = '';
    this.email = '';
    this.landPhoneNumber = '';
    this.mobilePhoneNumber = '';
    this.vkn = '';
    this.taxOffice = '';
    this.imageUrl = '';
  }
}
