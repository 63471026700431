<div class="initial-page" *ngIf="opened">
  <div class="initial-page__header">
    <div class="initial-page__header-title">
      <h3>Sepet özeti</h3>
      <span (click)="close()">
        <img
          src="/assets/images/close.svg"
          width="14"
          height="14"
          alt="close"
          title="close"
        />
      </span>
    </div>
    <app-link-component
      size="lg"
      color="white"
      label="Sepet oluştur"
      (linkClicked)="createNewCart()"
      icon="../../../assets/images/cart/add-icon.svg"
    >
    </app-link-component>
  </div>
  <div class="initial-page__container">
    <div class="empty-cart">
      <p class="empty-cart__title">
        Ürün eklemek için <br />
        sepet oluşturmalısınız.
      </p>
      <img
        src="/assets/images/cart/empty-cart.svg"
        width="190"
        height="190"
        alt="empty cart"
        title="empty cart"
      />
    </div>
  </div>
</div>
