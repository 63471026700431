<div
  class="message-box message-box--{{ options.messageClass }} "
  [style.display]="state.value === 'inactive' ? 'none' : ''"
  [ngClass]="{ 'detail-link': options.payload.messageLinkText }"
>
  <div class="container">
    <div class="icon">
      <div [ngSwitch]="options.messageClass">
        <div *ngSwitchCase="'success'">
          <img
            src="/assets/images/auth/message-box-success.svg"
            alt="success icon"
            title="success icon"
            width="20"
            height="20"
          />
        </div>
        <div *ngSwitchCase="'warning'">
          <img
            src="/assets/images/auth/message-box-warning.svg"
            alt="warning icon"
            title="warning icon"
            width="20"
            height="20"
          />
        </div>
        <div *ngSwitchCase="'error'">
          <img
            src="/assets/images/auth/message-box-error.svg"
            alt="error icon"
            title="error icon"
            width="20"
            height="20"
          />
        </div>
        <div *ngSwitchDefault>
          <img
            src="/assets/images/auth/message-box-default.svg"
            alt="message icon"
            title="message icon"
            width="20"
            height="20"
          />
        </div>
      </div>
    </div>
    <div class="text">
      <p *ngIf="title" [attr.aria-label]="title">
        {{ title }}
      </p>
      <div
        class="message-text"
        *ngIf="message && options.enableHtml"
        role="alert"
        [innerHTML]="message"
      ></div>
      <div
        class="message-text"
        *ngIf="message && !options.enableHtml"
        role="alert"
        [attr.aria-label]="message"
      >
        {{ message }}
      </div>
      <a (click)="onClick()" *ngIf="options.payload.messageLinkText">{{
        options.payload.messageLinkText
      }}</a>
    </div>
  </div>
  <div clas="icon">
    <a
      [ngClass]="title && message ? 'clear-title' : 'clear'"
      (click)="action()"
    >
      <img src="/assets/images/close.svg" alt="close icon" title="close icon" />
    </a>
  </div>
</div>
