import { Component, Input } from '@angular/core';

import { GeneralMessage } from '../../../lib/core/models/general-message';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent {
  @Input() errorModel: GeneralMessage | undefined;
  @Input() type: string | undefined;
  @Input() message: string | undefined;
  @Input() messageTitle: string | undefined;
  @Input() isBold: boolean | undefined;

  constructor() {}
}
