import { DatePipe, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';

import { groupBy } from '../../../lib/core/services/utils.service';
import { GeneralMessage } from '../../core/models/general-message';
import { AuthenticationService } from '../../domains/auth/services/authentication.service';
import { Basket, BasketStatus } from '../../domains/basket/models/basket';
import { BasketSummary } from '../../domains/basket/models/basket-summary';
import {
  BasketItemPrice,
  BasketItemSummary
} from '../../domains/basket/models/basketitem-summary';
import { BasketService } from '../../domains/basket/services/basket.service';
import { InitialPageComponent } from './initial-page/initial-page.component';
import { MiniCartValidationComponent } from './mini-cart-validation/mini-cart-validation.component';
import { UpsertBasketComponent } from './upsert-basket/upsert-basket.component';

@Component({
  selector: 'app-mini-cart',
  templateUrl: './mini-cart.component.html',
  styleUrls: ['./mini-cart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MiniCartComponent implements AfterViewInit {
  @ViewChild('upsertBasketComponent')
  upsertBasketComponent!: UpsertBasketComponent;

  @ViewChild('deleteCartValidation')
  deleteCartValidation!: MiniCartValidationComponent;

  @ViewChild('deleteCartValidationAuthorized')
  deleteCartValidationAuthorized!: MiniCartValidationComponent;

  @ViewChild('deleteProductValidation')
  deleteProductValidation!: MiniCartValidationComponent;

  @ViewChild('initialPage')
  initialPage!: InitialPageComponent;

  _isMiniCartOpen: boolean = false;

  @HostBinding('class')
  classes = 'mini-cart';

  offerMessage: GeneralMessage | undefined;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private basketService: BasketService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private permissionService: NgxPermissionsService,
    private datePipe: DatePipe,
    private router: Router
  ) {
    basketService.miniBasketComponent = this;
    this.basketService.miniCartOpenSubject.subscribe((isOpen: boolean) => {
      this._isMiniCartOpen = isOpen;
      let htmlTag = this.document.querySelectorAll('html')[0];
      if (isOpen) {
        htmlTag?.classList.add('overflow-hidden');
      } else {
        htmlTag?.classList.remove('overflow-hidden');
      }
    });
  }

  selectedBasketId: string | undefined;
  _basketName: string = '';
  deletedCartId: string = '';
  organizationId: string = '';
  isCartCreatedEmpty: boolean = false;
  isCartFilled: boolean = false;
  basketItemsSubject = new BehaviorSubject<BasketItemSummary[]>([]);
  basketSummaries: BasketSummary[] = [];
  deletedOption: BasketItemSummary | undefined;
  selectedBasket: BasketSummary | undefined;

  ngAfterViewInit(): void {
    this.basketService.basketSummariesSubject.subscribe(
      (basketSummaries: BasketSummary[]) => {
        this.basketSummaries = basketSummaries;
        this.refreshView();
      }
    );

    this.basketService.currentBasketSubject.subscribe(
      (currentBasket: Basket | undefined) => {
        if (currentBasket) {
          this.loadBasket(currentBasket);
          this.selectedBasket = this.basketSummaries?.find(
            (x: BasketSummary) => x.selected
          );
          if (currentBasket.status !== BasketStatus.Active) {
            this.cartMessage(currentBasket);
          } else {
            this.offerMessage = undefined;
          }
        } else {
          this.selectedBasket = undefined;
          this.selectedBasketId = '';
          this.basketItemsSubject.next([]);
        }
        //this.backlink();

        this.cdr.detectChanges();
      }
    );

    this.basketService.update();
  }

  cartMessage(currentBasket: Basket) {
    switch (currentBasket.status) {
      case BasketStatus.OfferWaiting:
        this.offerMessage = {
          messageTitle: 'Teklif bekleniyor',
          messageText: `${this.datePipe.transform(
            currentBasket.offerDate,
            'dd.MM.yyy'
          )} tarihinde teklif istendi`,
          messageType: 'default'
        };
        break;
      case BasketStatus.OfferAccepted:
        this.offerMessage = {
          messageTitle: `Teklif geldi (${this.datePipe.transform(
            currentBasket.offerAcceptDate,
            'dd.MM.yyy'
          )})`,
          messageText: `Teklif ${this.datePipe.transform(
            currentBasket.offerDeadline,
            'dd.MM.yyy'
          )} tarihine kadar geçerlidir.`,
          messageType: 'success'
        };
        break;
      case BasketStatus.OfferRejected:
        this.offerMessage = {
          messageTitle: 'Teklif reddedildi',
          messageText: 'Satıcı teklif vermeyi reddetti',
          messageType: 'error'
        };
        break;
      case BasketStatus.OfferTimeout:
        this.offerMessage = {
          messageTitle: 'Teklif süresi doldu',
          messageText: 'Güncel fiyatlardan siparişini tamamlayabilirsin',
          messageType: 'error'
        };
        break;
    }
  }

  close() {
    this.basketService.closeMiniCart();
  }

  open() {
    this.basketService.openMiniCart();
    this.refreshView();
  }

  refreshView() {
    this.initialPage.hide();

    if (this.basketSummaries?.length === 0) {
      this.selectedBasketId = undefined;
      this.initialPage.show();
    }
  }

  loadBasket(basket: Basket) {
    this.authenticationService.getUserInfo().subscribe((userInfo) => {
      this.selectedBasketId = basket.id;
      this._basketName = basket.name;
      let currentAccountId = userInfo?.accountId;

      let basketItems = basket.options.map((x) => {
        let priceItemsObj = groupBy(x.items, 'price');
        let priceItems: BasketItemPrice[] = [];
        for (let key in priceItemsObj) {
          priceItemsObj[key].map((x: any) => {
            x.campaignTitle =
              x.campaignTitle || basket.itemsTotalPriceCampaignTitle || '';
          });

          let campaignTitleGroup = groupBy(priceItemsObj[key], 'campaignTitle');
          for (let campaignTitle in campaignTitleGroup) {
            priceItems.push({
              count: campaignTitleGroup[campaignTitle].length,
              unitPrice: Number(key),
              price: Number(key) * campaignTitleGroup[campaignTitle].length,
              shippingTime: Math.max(
                ...priceItemsObj[key].map((y: any) => y.shippingTime)
              ),
              carryingCharge: 0,
              accountNames: priceItemsObj[key]
                .map((x: any) =>
                  x.accountFirstName
                    ? x.accountFirstName + ' ' + x.accountLastName
                    : ''
                )
                .filter(
                  (x: string, index: number, self: string) =>
                    x.length > 0 && self.indexOf(x) === index
                ),
              discountedPrice: priceItemsObj[key]
                .map((x: any) => x.discountedPrice)
                .reduce((a: number, b: number) => a + b),
              campaignTitle:
                campaignTitle.length > 0 ? campaignTitle : undefined
            });
          }
        }
        let optionId = `${x.productId}_${x.variants
          .map((x) => x.attributeItemId)
          .join('_')}`;

        return <BasketItemSummary>{
          _id: optionId,
          brand: x.brand,
          imageUrl: x.imageUrl,
          productId: x.productId,
          productTitle: x.productTitle,
          variants: x.variants.sort((a, b) =>
            a.attributeName > b.attributeName ? 1 : -1
          ),
          disabled:
            x.items.filter((x) => x.accountId === currentAccountId).length ===
            0,
          count: x.items.length,
          total: x.items.map((x) => x.price).reduce((a, b) => a + b),
          priceItems: priceItems,
          shippingTime: Math.max(...x.items.map((y) => y.shippingTime))
        };
      });
      this.basketItemsSubject.next(basketItems);
    });
  }

  onSelectBasket(basket: BasketSummary) {
    this.basketService.setCurrentBasketId(basket.id).subscribe(() => {
      this.basketService.update();
    });
  }

  onBasketDeleted() {
    this.basketService.deleteBasket(this.deletedCartId).subscribe();
  }

  deleteCart(id: string) {
    this.deletedCartId = id;

    if (this.permissionService.getPermission('DELETE_BASKET')) {
      this.deleteCartValidationAuthorized.open();
    } else {
      this.authenticationService.getUserInfo().subscribe((userInfo) => {
        this.basketService.getBasket(id).subscribe((basket: Basket) => {
          if (basket) {
            let currentAccountId = userInfo?.accountId;
            let addedByOthers = false;
            basket.options.forEach((option) => {
              option.items.forEach((item) => {
                if (item.accountId !== currentAccountId) {
                  addedByOthers = true;
                  return;
                }
              });
            });

            if (addedByOthers) {
              this.deleteCartValidation.open();
            } else {
              this.deleteCartValidationAuthorized.open();
            }

            this.cdr.detectChanges();
          }
        });
      });
    }
  }

  createNewCart() {
    this.upsertBasketComponent.show();
  }

  onRemoveItemFromCart(option: BasketItemSummary) {
    this.deleteProductValidation.open();
    this.deletedOption = option;
  }

  productRouteRedirected() {
    this.close();
  }

  deleteCartItem() {
    if (this.deletedOption) {
      this.basketService
        .removeItemsFromBasket({
          productId: this.deletedOption.productId,
          attributeItemIds: this.deletedOption.variants.map(
            (x) => x.attributeItemId
          )
        })
        .subscribe();
    }

    this.refreshView();
  }

  routeCart() {
    this.router.navigate(['/cart']);
    this.close();
  }
}
