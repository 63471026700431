import { Injectable } from '@angular/core';
import { CanLoad, UrlTree } from '@angular/router';
// import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

const responses: any = {};
const subscribers: any = {};

@Injectable({
  providedIn: 'root'
})
export class SignalRService implements CanLoad {
  // connection: signalR.HubConnection | undefined;
  promise: Promise<void> | undefined;
  get responses() {
    return responses;
  }

  get subscribers() {
    return subscribers;
  }

  constructor() {
    // if (environment.socketEnabled) {
    //   this.connection = new signalR.HubConnectionBuilder()
    //     .withUrl(environment.apiUrl + '/response', { withCredentials: false })
    //     .withAutomaticReconnect()
    //     .build();
    //   this.promise = this.connection.start();
    //   this.connection.on('response', (responseId, responseData) => {
    //     var subscriber = subscribers[responseId];
    //     if (subscriber) {
    //       subscriber.next(responseData);
    //       delete subscribers[responseId];
    //     } else {
    //       responses[responseId] = responseData;
    //     }
    //   });
    // }
  }
  canLoad():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return new Observable((observer) => {
      if (!environment.socketEnabled) {
        observer.next(true);
        observer.complete();

        return;
      }

      // if (this.connection?.state !== 'Connecting') {
      //   observer.next(true);
      //   observer.complete();
      // }

      this.promise?.then(() => {
        observer.next(true);
        observer.complete();
      });
    });
  }

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return new Observable((observer) => {
      if (!environment.socketEnabled) {
        observer.next(true);
        observer.complete();

        return;
      }

      // if (this.connection?.state !== 'Connecting') {
      //   observer.next(true);
      //   observer.complete();
      // }

      this.promise?.then(() => {
        observer.next(true);
        observer.complete();
      });
    });
  }
}
