import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { BasketService } from '../../../domains/basket/services/basket.service';

@Component({
  selector: 'app-upsert-basket',
  templateUrl: './upsert-basket.component.html',
  styleUrls: ['./upsert-basket.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpsertBasketComponent {
  createBasketForm: any;
  opened: boolean = false;
  isEdit: boolean = false;

  constructor(private basketService: BasketService, private fb: FormBuilder) {
    this.createBasketForm = this.fb.group({
      basketName: new FormControl('', Validators.required)
    });
  }

  save() {
    if (!this.createBasketForm.valid) {
      this.createBasketForm.markAllAsTouched();
      return;
    }

    let formValue = this.createBasketForm.getRawValue();
    let basketName = formValue.basketName.trim();
    if (this.isEdit) {
      this.editCart(basketName);
    } else {
      this.createCart(basketName);
    }
  }

  createCart(basketName: string) {
    this.basketService.createBasket(basketName).subscribe(() => {
      this.backlink();
    });
  }

  editCart(basketName: string) {
    this.basketService.updateSelectedBasket(basketName).subscribe(() => {
      this.backlink();
    });
  }

  show(val: string | undefined = undefined) {
    this.createBasketForm = this.fb.group({
      basketName: new FormControl(val, Validators.required)
    });

    this.isEdit = typeof val === 'string';
    this.opened = true;
  }

  backlink() {
    this.opened = false;
  }
}
