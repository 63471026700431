import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appCreditCardMask]'
})
export class CreditCardMaskDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    this.onInputChange(event);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) {
    this.onInputChange(event.target.value);
  }

  onInputChange(event?: any) {
    if (event == null) {
      return '';
    }
    let newVal = event.replace(/\D/g, '');
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 4) {
      newVal = newVal.replace(/^(\d{0,4})/, '$1');
    } else if (newVal.length <= 9) {
      newVal = newVal.replace(/^(\d{0,4})(\d{0,4})/, '$1 $2');
    } else if (newVal.length <= 14) {
      newVal = newVal.replace(/^(\d{0,4})(\d{0,4})(\d{0,4})/, '$1 $2 $3');
    } else {
      newVal = newVal.replace(
        /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/,
        '$1 $2 $3 $4'
      );
    }

    if (newVal === '') {
      this.ngControl.control?.setValue(null);
    } else {
      this.ngControl.control?.setValue(newVal, {
        emitModelToViewChange: false,
        emitEvent: false
      });
      this.ngControl.valueAccessor?.writeValue(newVal);
    }
    return newVal;
  }
}
