<div
  [ngClass]="{ opened: _isDrawerOpen }"
  class="drawer-overlay"
  (click)="close()"
></div>
<div
  class="drawer__container"
  [ngClass]="{
    opened: _isDrawerOpen,
    left: direction === 'left',
    right: direction === 'right'
  }"
  [ngStyle]="{
    width: width + 'px'
  }"
>
  <div class="drawer__content">
    <div
      class="drawer__content__header"
      *ngIf="!customHeader"
      [ngClass]="{ shadow_none: !shadow }"
    >
      <span class="drawer-title" *ngIf="title">{{ title }}</span>
      <img
        src="/assets/images/close-black.svg"
        width="24"
        height="24"
        alt="close"
        title="close"
        class="drawer-img"
        (click)="close()"
      />
    </div>
    <ng-content *ngIf="customHeader" select="[header]"></ng-content>
    <ng-content></ng-content>
  </div>
</div>
