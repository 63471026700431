import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-initial-page',
  templateUrl: './initial-page.component.html',
  styleUrls: ['./initial-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InitialPageComponent {
  @Output() closeMiniCart = new EventEmitter();
  @Output() createCart = new EventEmitter();
  opened: boolean = false;

  show() {
    this.opened = true;
  }

  hide() {
    this.opened = false;
  }

  close() {
    this.closeMiniCart.emit();
  }

  createNewCart() {
    this.createCart.emit();
  }
}
