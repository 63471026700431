export interface AclInterface {
  permissions: string[];
  roles: string[];
}

export class AclModel implements AclInterface {
  // default permissions
  public permissions: string[] = [];
  // store an object of current user roles
  public roles: string[] = [];

  constructor() {}
}
