import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
export enum IconType {
  'CLEAR' = 'clear',
  'PASSWORD' = 'password',
  'NONE' = 'none'
}

export enum InputType {
  'TEXT' = 'text',
  'EMAIL' = 'email',
  'PASSWORD' = 'password',
  'NUMBER' = 'number',
  'PHONE' = 'phone',
  'TEXTAREA' = 'textarea',
  'SELECT' = 'select',
  'DATE' = 'date'
}
declare var setTimeout: any;

@Component({
  selector: 'app-input-component',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor, OnInit, OnChanges
{
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() icon: any;
  @Input() value = '';
  @Input() inputType: any;
  @Input() autocomplete = 'off' || 'on';
  @Input() size = 'lg' || 'md' || 'sm' || 'xs';
  @Input() form: any = null;
  @Input() controlName: any;
  @Input() class: string = '';
  @Input() iconClass: string = '';
  @Input() maxLength: any;
  @Input() name: any;
  @Input() minLength: any;
  @Input() labelButton: any;
  @Input() isPhone: boolean = false;
  @Input() isCreditCard: boolean = false;
  @Input() isCreditCardDateYear: boolean = false;
  @Input() isEmail: boolean = false;
  @Input() showIcon: boolean = true;
  @Input() canChange: boolean = true;
  @Input() typeText: string | undefined;
  @Input() info: string = '';
  @Input() requiredErrorMessage: string = 'Bu alan zorunludur';
  @Input() patternErrorMessage: string = 'Kurallara uygun şifre giriniz';
  @Input() emailErrorMessage: string = 'Lütfen geçerli bir e-posta giriniz';
  @Input() minErrorMessage: string = 'Eksik numara';
  @Input() maxErrorMessage: string = 'Fazla numara';
  @Input() pastDateErrorMessage: string = 'Geçerli bir tarih giriniz';
  @Input() matchingErrorMessage: string = 'Şifreler aynı olmalıdır';
  @Output() labelButtonClicked = new EventEmitter<boolean>();
  @Output() search = new EventEmitter<null>();
  @Input() searchIcon: boolean = false;
  @Input() isNumbersOnly: boolean = false;
  @Input() markAsValid: boolean = false;
  imageSrc: string = '';
  selectedClass: string = '';
  pattern: string = '';
  isFocus: boolean = false;
  timeOut = null;
  showInfo: boolean = false;

  isDisabled: boolean = false;
  onChange: any = (event: any) => {
    this.value = event.target.value;
  };
  onTouch: any = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
  }
  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit(): void {
    switch (this.inputType) {
      case InputType.PASSWORD:
        this.imageSrc = '../../../assets/images/show-password-icon.svg';
        break;
      default:
        this.imageSrc = '../../../assets/images/close.svg';
        break;
    }
    this.selectedClass = `input-wrapper input-wrapper--${this.size} input-wrapper--${this.class}`;
    if (this.label?.length > 0) {
      this.iconClass += ' labelled';
    }
  }

  onFocus() {
    this.isFocus = true;
  }

  onBlur() {
    this.isFocus = false;
  }

  triggerFnc() {
    switch (this.inputType) {
      case InputType.PASSWORD:
        this.imageSrc = '../../../assets/images/hide-password-icon.svg';
        this.onHandlePassword();
        break;
      default:
        if (this.icon === IconType.PASSWORD) {
          this.imageSrc = '../../../assets/images/show-password-icon.svg';
          this.onHandlePassword();
          return;
        }
        this.onHandleClear();
        break;
    }
  }

  onHandleClear() {
    this.form.controls[this.controlName].setValue('');
  }

  onHandlePassword() {
    setTimeout(() => {
      this.isFocus = true;
      this.cdr.detectChanges();
    }, 0);
    this.inputType =
      this.inputType == InputType.PASSWORD
        ? InputType.TEXT
        : InputType.PASSWORD;
  }

  handleLabelButtonClick() {
    this.labelButtonClicked.emit(true);
  }

  handleSearchIconClick() {
    this.search.emit();
  }
}
