import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  registerLocaleData
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeTr from '@angular/common/locales/tr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';

import { InterceptorService } from '../../lib/core/services/interceptor.service';
import { CustomToastrComponent } from '../../lib/partials/custom-toastr/custom-toastr.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './content/layout/layout.module';

registerLocaleData(localeTr);
@NgModule({
  declarations: [AppComponent, CustomToastrComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot({
      toastComponent: CustomToastrComponent
    })
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    { provide: LOCALE_ID, useValue: 'tr-TR' },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
