<ng-container *ngIf="icon.length > 0 && iconLocation === 'left'">
  <img
    [src]="icon"
    alt="{{ label }}"
    [class]="imageClass"
    [ngClass]="label.length > 0 ? 'has-left-icon' : ''"
  />
</ng-container>
<button [class]="class" [ngClass]="{ 'hide-label': hideLabelForMobile }">
  {{ label }}
  <ng-content></ng-content>
</button>
<ng-container *ngIf="icon.length > 0 && iconLocation === 'right'">
  <img
    [src]="icon"
    alt="{{ label }}"
    [class]="imageClass"
    [ngClass]="label.length > 0 ? 'has-right-icon' : ''"
  />
</ng-container>
