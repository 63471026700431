import { Injectable } from '@angular/core';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

import { GeneralMessage } from '../../../lib/core/models/general-message';

@Injectable({ providedIn: 'root' })
export class CustomToastrService {
  options: GlobalConfig;

  constructor(public toastr: ToastrService) {
    this.options = this.toastr.toastrConfig;
  }

  success(message: GeneralMessage) {
    const opt = { ...this.options, payload: { ...message } };
    opt.enableHtml = true;
    opt.toastClass = 'message-box';
    opt.messageClass = 'success';
    this.toastr.show(message.messageText, message.messageTitle, opt);
  }

  error(errorModel: GeneralMessage) {
    const opt = { ...this.options, payload: { ...errorModel } };
    opt.enableHtml = true;
    opt.toastClass = 'message-box';
    opt.messageClass = 'error';
    this.toastr.error(errorModel.messageText, errorModel.messageTitle, opt);
  }

  warning(errorModel: GeneralMessage) {
    const opt = { ...this.options, payload: { ...errorModel } };
    opt.enableHtml = true;
    opt.toastClass = 'message-box';
    opt.messageClass = 'warning';
    this.toastr.warning(errorModel.messageText, errorModel.messageTitle, opt);
  }

  info(errorModel: GeneralMessage) {
    const opt = { ...this.options, payload: { ...errorModel } };
    opt.enableHtml = true;
    opt.toastClass = 'message-box';
    opt.messageClass = 'default';
    this.toastr.info(errorModel.messageText, errorModel.messageTitle, opt);
  }
}
