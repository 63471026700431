import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output
} from '@angular/core';
declare var window: any;
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input()
  imageLink: string | undefined = undefined;

  @Input()
  iconLink: string | undefined = undefined;
  @Input()
  title: string = '';
  @Input()
  bodyText: string = '';
  @Input()
  onlyMobile: boolean = false;

  @Input()
  isHTML: boolean = false;

  @Input()
  size: 'sm' | 'md' | 'lg' = 'sm';

  _isModalOpen: boolean = false;

  @Input()
  set isOpen(value: boolean) {
    this._isModalOpen = value;
    if (value) {
      this.lastScrollTop =
        window.pageYOffset || this.document.documentElement.scrollTop;
      this.lastScrollLeft =
        window.pageXOffset || this.document.documentElement.scrollLeft;
    }
  }

  lastScrollTop = 0;
  lastScrollLeft = 0;

  @HostListener('document:scroll', ['$event'])
  onScroll() {
    if (this._isModalOpen) {
      window.scrollTo(this.lastScrollLeft, this.lastScrollTop);
    }
  }

  @Output()
  isOpenChange = new EventEmitter<boolean>(false);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  close() {
    this.isOpen = false;
    this.isOpenChange.emit(false);
  }
}
