import { Injectable } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';

import { AuthenticationService } from '../../domains/auth/services/authentication.service';
import { PriceService } from '../../domains/price/services/price.service';
import { AclModel } from '../models/acl';

@Injectable({
  providedIn: 'root'
})
export class AclService {
  public aclModel: AclModel;
  public onAclUpdated$: BehaviorSubject<AclModel>;

  constructor(
    private roleService: NgxRolesService,
    private permService: NgxPermissionsService,
    private authService: AuthenticationService,
    private priceService: PriceService
  ) {
    // set initial permission model
    this.aclModel = new AclModel();
    this.onAclUpdated$ = new BehaviorSubject(this.aclModel);
  }

  init() {
    this.authService.getUserRoles().subscribe((roles) => {
      this.setCurrrentUserRoles(roles);
    });

    // subscribe to credential changed, eg. after login response
    // this.authService.onCredentialUpdated$
    //   .pipe(mergeMap(() => this.authService.getUserRoles()))
    //   .subscribe((roles) => this.setCurrrentUserRoles(roles));

    this.authService.onCredentialUpdated$.subscribe((account) => {
      if (account) {
        this.setCurrrentUserRoles(account.roles);
      } else {
        this.onAclUpdated$.next(new AclModel());
      }
    });

    // subscribe to acl data observable
    this.onAclUpdated$.subscribe((acl) => {
      // load default permission list
      this.permService.loadPermissions(acl.permissions);
      this.priceService.setShowPrice(
        !!this.permService.getPermission('VIEW_PRICE')
      );

      acl.roles.forEach((role: string) => {
        // add to role service
        this.roleService.addRole(role, () => {
          return true;
        });
      });
    });
  }

  /**
   * Set AclModel and fire off event that all subscribers will listen to
   * @param aclModel
   */
  setModel(aclModel: AclModel): any {
    aclModel = Object.assign({}, this.aclModel, aclModel);
    this.onAclUpdated$.next(aclModel);
  }

  setCurrrentUserRoles(roles: string[]): any {
    // update roles if the credential data has roles
    if (roles != null && roles.length > 0) {
      this.aclModel.roles = [...roles];
      this.authService.getUserInfo().subscribe((userInfo) => {
        this.aclModel.permissions = [...userInfo.permissions];
        // set updated acl model back to service
        this.setModel(this.aclModel);
      });
    }
  }
}
